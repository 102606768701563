







































































































import { Vue, Component, Watch, Provide } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Card, Steps, Step } from "element-ui";
import { getChannel } from "../components/sign";

@Component({
    components: {
        "el-card": Card,
        "el-steps": Steps,
        "el-step": Step,

        addBasicRegistForm(resolve) {
            require(["@/components/sign/BasicRegistForm.vue"], resolve);
        },

        addLoginForm(resolve) {
            require(["@/components/sign/LoginForm.vue"], resolve);
        },

        addProjectBasicForm(resolve) {
            require(["@/components/sign/ProjectBasicForm.vue"], resolve);
        },

        // addProjectTechForm(resolve) {
        //     require(["@/components/sign/ProjectTechForm.vue"], resolve);
        // },

        // addProjectTeamForm(resolve) {
        //     require(["@/components/sign/ProjectTeamForm_2.vue"], resolve);
        // },

        // addProjectFileForm(resolve) {
        //     require(["@/components/sign/ProjectFileForm.vue"], resolve);
        // }
    }
})
export default class Register extends Vue {
    @Getter("getToken") token: any;
    @Action("getSetToken") setToken: any;
    @Action("getSetUserId") setUserId: any;
   
    @Action("getSetLang") setLang: any;

    channel: object = {}; //渠道信息

    component = {
        /*
            name:异步import组件的方法名,
            id:组件id,
            ref:组件ref,
            childName:子组件ref 调用子组件方法时传参获得子组件,
        */
        currentComponent: "",
        customer_id: "",
        customer_ref: "",
        childName: ""
    };

    stepActived: number = this.$route.params.step
        ? Number(this.$route.params.step)
        : -1; //步骤

    pid: any = this.$route.params.pid ? this.$route.params.pid : undefined; //项目id


    @Provide()
    isdisabled: boolean = false;

    @Provide()
    changeSub(is?: boolean) {
        if (is) {
            this.isdisabled = is;
        } else {
            this.isdisabled == true
                ? (this.isdisabled = false)
                : (this.isdisabled = true);
        }
    }

    /* computed 步骤条参数 */
    get stepOpt() {
        let stepOpt = [
            {
                title: this.$t("sign.pStep_1")
            },
            {
                title: this.$t("sign.pStep_2")
            },
            {
                title: "完成"
            },
            
        ];
        return stepOpt;
    }

    /* computed card左上角标题 */
    get signtypeName() {
        if (this.component.currentComponent === "addBasicRegistForm") {
            return this.$t("sign.regist");
        } else if (this.component.currentComponent === "addLoginForm") {
            return this.$t("sign.login");
        } else if (this.component.currentComponent === "addProjectBasicForm") {
            return this.$t("sign.pStep_2");
        } else if (this.component.currentComponent === "addProjectTechForm") {
            return this.$t("sign.pStep_3");
        } else if (this.component.currentComponent === "addProjectTeamForm") {
            return this.$t("sign.pStep_4");
        } else if (this.component.currentComponent === "addProjectFileForm") {
            return this.$t("sign.pStep_5");
        } else {
            return this.$t("sign.regist");
        }
    }

    /* computed cardy右上角链接文本 */
    get cardHeadLink() {
        if (this.component.currentComponent === "addBasicRegistForm") {
            return this.$t("sign.noAccount");
        } else if (this.component.currentComponent === "addLoginForm") {
            return this.$t("sign.havedAccount");
        } else {
            return "";
        }
    }

    /* 提交按钮的文本 */
    get subbtntxt() {
        if (this.stepActived === 0 || this.stepActived === -1) {
            return this.$t("sign.sub");
        } else {
            return this.$t("sign.next");
        }
    }

    /* 切换登录或注册表单 */
    changeSign() {
        if (this.component.currentComponent === "addBasicRegistForm") {
            this.changeComp(
                "addLoginForm",
                "loginForm",
                "loginForm",
                "loginForm"
            );
        } else if (this.component.currentComponent === "addLoginForm") {
            this.changeComp(
                "addBasicRegistForm",
                "basicRegistForm",
                "basicRegistForm",
                "basicRegistForm"
            );
        } else {
            alert("参数错误：参数既不指代注册也不指代登录");
        }
    }

    /* 
        加载组件，
        name:异步import组件的方法名,
        id:组件id,
        ref:组件ref,
        childName:子组件ref
    */
    changeComp(name: string, id: string, ref: string, childName: string) {
        this.component.currentComponent = name;
        this.component.customer_id = id;
        this.component.customer_ref = ref;
        this.component.childName = childName;
    }

    /* 提交 */
    sub() {
        this.$nextTick(() => {
            let loading: any;
            let isLoading = async () => {
                loading = this.$loading({
                    lock: true,
                    text: "加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            };
            let closeLoading = async () => {
                loading.close();
            };

            //调用子组件提交方法
            (this.$refs[this.component.customer_ref] as any).sub(
                this.component.childName,
                (status: any, data: any) => {
                    this.afterSub(status, data, closeLoading);
                },
                isLoading
            );
        });
    }

    /* 提交后 */
    afterSub(status: number, data: any, closeLoading: any) {
        // console.log(data);
        if (status === 0) {
            if (this.component.currentComponent === "addBasicRegistForm") {
                sessionStorage.setItem("api_token", data.api_token);
                sessionStorage.setItem("id", data.id);
                this.setToken(data.api_token);
                this.setUserId(data.id);
                this.$nextTick(() => {
                    this.$notify({
                        title: "成功",
                        message: "登录成功",
                        type: "success"
                    });
                });
            }
            let pid = this.stepActived <= 0 ? data.case_id : data.id;

            if (Array.isArray(data) == true) {
                pid = data[0].case_id;
            }

            if (pid == null) {
                pid = undefined;
            }
            closeLoading();

            if (this.stepActived == 1) {
                this.$router.push({
                    name: "userinfo"
                });
            } else {
                this.$router.push({
                    name: "register",
                    params: {
                        step: String(this.stepActived + 1),
                        pid: this.stepActived + 1 >= 1 ? pid : undefined
                    }
                });
            }
        } else {
            closeLoading();
        }
    }

    /* 清空 */
    reset() {
        this.$nextTick(() => {
            //调用子组件清空方法
            (this.$refs[this.component.customer_ref] as any).resetForm(
                this.component.childName
            );
        });
    }

    /* 响应'test'按钮 */
    test() {
        (this.$refs[this.component.customer_ref] as any).test();
    }

    /* 改变表单 */
    changeForm() {
        if (this.stepActived === 0) {
            this.changeComp(
                "addBasicRegistForm",
                "basicRegistForm",
                "basicRegistForm",
                "basicRegistForm"
            );
        } else if (this.stepActived === 1) {
            this.changeComp(
                "addProjectBasicForm",
                "projectBasicForm",
                "projectBasicForm",
                "projectBasicForm"
            );
        } else if (this.stepActived === -1) {
            this.changeComp(
                "addBasicRegistForm",
                "basicRegistForm",
                "basicRegistForm",
                "basicRegistForm"
            );
        } else {
            this.changeComp("", "", "", "");
        }
    }

    //添加成员
    addTeamMembers() {
        if (this.component.currentComponent == "addProjectTeamForm") {
            (this.$refs[this.component.customer_ref] as any).addTeamMembers();
        }
    }

    lastStep(step: number) {
        this.$nextTick(() => {
            let loading: any;
            let isLoading = async () => {
                loading = this.$loading({
                    lock: true,
                    text: "加载中",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                });
            };
            let closeLoading = async () => {
                loading.close();
            };

            //调用子组件提交方法
            (this.$refs[this.component.customer_ref] as any).sub(
                this.component.childName,
                (status: any, data: any) => {
                    // this.afterSub(status, data, closeLoading);
                    closeLoading();
                    this.$router.push({
                        name: "register",
                        params: { step: String(step - 1), pid: this.pid }
                    });
                },
                isLoading
            );
        });
    }

    /* 监听路由改变注册步骤 */
    @Watch("$route")
    routeChange(to: any, from: any) {
        if (
            Number(from.params.step) + 1 == to.params.step ||
            Number(from.params.step) - 1 == to.params.step ||
            Number(from.params.step) == to.params.step
        ) {
        } else {
            if (to.params.step == "-1" || to.params.step == "0") {
            } else {
                this.$router.push({ path: "/" });
                console.log("to既不是-1也是0，from与to也不是相邻步骤");
                return;
            }
        }
        document.documentElement.scrollTop = 0; //路由改变后，页面滚动到顶
        this.stepActived = to.params.step ? Number(to.params.step) : -1;
        this.changeForm();
    }

    

    //获取渠道信息
    getChannel() {
        if (this.$route.query.codel) {
            getChannel(this.$route.query.codel as string).then((res: any) => {
                if (res.status === 0) {
                    // this.formIsShow = false;

                    this.channel = res.data;
                    sessionStorage.setItem("channel_id", res.data.id);
                    sessionStorage.setItem("channel_lang", res.data.lang);
                    this.setLang(res.data.lang);
                    // this.formIsShow = !this.formIsShow;
                    console.log(res);
                } else {
                    console.log(res.msg);
                }
            });
        }
    }

    /**
     * 最后一步跳过
     */
    skip() {
        this.$router.push({
            name: "userinfo"
        });
    }

    mounted() {
        /* 组件加载完毕后，加载账号注册表单 */
        this.changeForm();
        this.getChannel();
    }

    goStep(step: number) {
        if (step == -1) {
            this.$router.push({
                name: "register-plain"
            });
        } else {
            this.$router.push({
                name: "register",
                params: {
                    step: String(step),
                    pid: step >= 2 ? this.pid : undefined
                }
            });
        }
    }
}
